<script >
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    headingClickable: {
      type: Boolean,
      default: false,
    },
    leftDisabled: {
      type: Boolean,
      default: false,
    },
    rightDisabled: {
      type: Boolean,
      default: false,
    },
    columnCount: {
      type: Number,
      default: 7,
    },
    items: {
      type: Array,
      default: [],
    },
  },
  emits: ["heading", "left", "right", "elementClick"],
});
</script>

<template>
  <div
    class="picker-popup"
    :style="{ '--popout-column-definition': `repeat(${columnCount}, 1fr)` }"
    @mousedown.prevent
  >
    <div class="picker-popup__heading">
      <button
        class="secondary-button picker-popup__heading__button"
        :disabled="leftDisabled"
        @click.stop.prevent="$emit('left')"
      >
        <slot name="arrow-left">
          <img
            class="picker-popup__heading__icon"
            src="../../../static/icons/date_arrow_left.svg"
          />
        </slot>
      </button>
      <component
        :is="headingClickable ? 'button' : 'span'"
        class="picker-popup__heading__center"
        @click.stop.prevent="$emit('heading')"
      >
        <slot name="heading" />
      </component>
      <button
        class="secondary-button picker-popup__heading__button"
        :disabled="rightDisabled"
        @click.stop.prevent="$emit('right')"
      >
        <slot name="arrow-right">
          <img
            class="picker-popup__heading__icon"
            src="../../../static/icons/date_arrow_right.svg"
          />
        </slot>
      </button>
    </div>
    <div class="v3dp__body">
      <template v-if="'subheading' in $slots">
        <div class="picker-popup__subheading">
          <slot name="subheading" />
        </div>
      </template>
      <div class="picker-popup__elements">
        <slot name="body">
          <button
            v-for="item in items"
            :key="item.key"
            :disabled="item.disabled"
            :class="[{ selected: item.selected }, 'secondary-button']"
            @click.stop.prevent="$emit('elementClick', item)"
          >
            <span>{{ item.display }}</span>
          </button>
        </slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
.picker-popup {
  z-index: 10;
  position: absolute;
  text-align: center;
  background-color: #fff;
  border-radius: 3px;
  padding: 8px 0 1em;
  color: var(--text-color);
  margin-top: 0.5rem;
  display: block;
  box-shadow: none;
  width: 100%;
}

.picker-popup * {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.picker-popup button {
  background: none;
  border: none;
  outline: none;
}

.picker-popup button:not(:disabled) {
  cursor: pointer;
}

.picker-popup__heading {
  width: 100%;
  display: flex;
  height: 1.75rem;
  line-height: 1.75rem;
  font-weight: 14px;
}

button.picker-popup__heading__button {
  background: none;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #6d6d8e;
  border-radius: 4px;
}

.picker-popup__heading__center {
  flex: 1;
  font-weight: 700;
  font-size: 14px;
  text-transform: capitalize;
}

.picker-popup__heading__icon {
  height: 20px;
  width: 20px;
  stroke: #6d6d8e;
}

.picker-popup__heading__button:disabled .picker-popup__heading__icon {
  stroke: #d5d9e0;
}

.picker-popup__subheading {
  color: #6d6d8e;
  font-size: 11px;
  font-weight: 700;
}

.picker-popup__subheading,
.picker-popup__elements {
  display: grid;
  grid-template-columns: var(--popout-column-definition);
  font-size: 0.8em;
  text-transform: uppercase;
}

.picker-popup__elements {
  color: #000000;
  font-size: 14px;
  margin-top: 0.5rem;
}

.picker-popup__subheading {
  margin-top: 1em;
}

.v3dp__body {
  width: calc(100% - 10px);
  margin-left: 5px;
  margin-top: 2rem;
}

.picker-popup__elements button:disabled {
  color: #d5d9e0;
}

.picker-popup__elements button {
  padding: 0 4px;
}

.picker-popup__elements button span {
  display: block;
  line-height: 1.9em;
  height: 1.8em;
  border-radius: 4px;
}

.picker-popup__elements button:not(:disabled):hover span {
  background-color: #6D6D8E;
  color: #fff;
}

.picker-popup__elements button.selected span {
  border: 1px solid #6d6d8e;
}
</style>