<script>
import { defineComponent, ref, computed } from "vue";
import { isValid } from "date-fns";
import DayPicker from "./DayPicker.vue";

import { dateLocales } from "../../../utils";

const TIME_RESOLUTIONS = ["day"];

export default defineComponent({
  components: {
    DayPicker,
  },
  inheritAttrs: false,
  props: {
    locale: {
      type: String,
    },
    /**
     * `v-model` for selected date
     */
    modelValue: {
      type: Object,
      required: false,
    },
    /**
     * Dates not available for picking
     */
    disabledDates: {
      type: Object,
      required: false,
    },
    /**
     * Upper limit for available dates for picking
     */
    upperLimit: {
      type: Date,
      required: false,
    },
    /**
     * Lower limit for available dates for picking
     */
    lowerLimit: {
      type: Date,
      required: false,
    },
    /**
     * View on which the date picker should open. Can be either `year`, `month`, or `day`
     */
    startingView: {
      type: String,
      required: false,
      default: "day",
      validate: (v) => typeof v === "string" && TIME_RESOLUTIONS.includes(v),
    },
    /**
     * `date-fns`-type formatting for a month view heading
     */
    monthHeadingFormat: {
      type: String,
      required: false,
      default: "LLLL yyyy",
    },
    /**
     * `date-fns`-type formatting for the month picker view
     */
    monthListFormat: {
      type: String,
      required: false,
      default: "LLL",
    },
    /**
     * `date-fns`-type formatting for a line of weekdays on day view
     */
    weekdayFormat: {
      type: String,
      required: false,
      default: "EE",
    },
    /**
     * Day on which the week should start.
     *
     * Number from 0 to 6, where 0 is Sunday and 6 is Saturday.
     * Week starts with a Monday (1) by default
     */
    weekStartsOn: {
      type: Number,
      required: false,
      default: 1,
      validator: (value) => [0, 1, 2, 3, 4, 5, 6].includes(value),
    },
    /**
     * Disables datepicker and prevents it's opening
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * If set, lower-level views won't show
     */
    minimumView: {
      type: String,
      required: false,
      default: "day",
      validate: (v) => typeof v === "string" && TIME_RESOLUTIONS.includes(v),
    },
  },
  emits: {
    "update:modelValue": (value) =>
      value === null || value === undefined || isValid(value),
  },
  setup(props, { emit }) {
    const dateLocale = dateLocales[props.locale] || dateLocales["en-US"];
    const pageDate = ref(new Date());

    const selectDay = (date) => {
      emit("update:modelValue", new Date(date.key));
    };

    const initialView = computed(() => {
      const startingViewOrder = TIME_RESOLUTIONS.indexOf(props.startingView);
      const minimumViewOrder = TIME_RESOLUTIONS.indexOf(props.minimumView);
      return startingViewOrder < minimumViewOrder
        ? props.minimumView
        : props.startingView;
    });
    return {
      dateLocale,
      pageDate,
      selectDay,
      initialView,
      log: (e) => console.log(e),
    };
  },
});
</script>

<template>
  <div class="date-picker">
    <day-picker
      v-model:pageDate="pageDate"
      :selected="modelValue"
      :weekStartsOn="weekStartsOn"
      :lowerLimit="lowerLimit"
      :upperLimit="upperLimit"
      :disabledDates="disabledDates"
      :locale="dateLocale"
      :weekdayFormat="weekdayFormat"
      @select="selectDay"
    />
  </div>
</template>

<style>
.date-picker {
  position: relative;
  height: 400px;
}
</style>